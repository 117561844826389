<template>
  <b-overlay
  :show="loading"
  rounded="lg">
    <div>
      <b-breadcrumb>
        <b-breadcrumb-item :to="`/`">
          <b-icon
            icon="house-fill"
            scale="1.25"
            shift-v="1.25"
            aria-hidden="true"
          ></b-icon>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>Completed Drum Picking</b-breadcrumb-item>
      </b-breadcrumb>
      <br />
      <b-card title="Completed Drum Picking List">
        <b-col cols="12">
          <b-row class="mb-2">
            <b-col cols="4">
            </b-col>
            <b-col cols="4">
              <v-select
                class="style-chooser"
                v-model="searchSoNumber"
                :options="soNumberList"
                @search="ajaxSearch"
                placeholder="Filter by SO Number"
                :clearSearchOnSelect="true"
              />
            </b-col>
            <b-col cols="4">
              <b-form-input
                v-model="search"
                @input="debounceSearch"
                type="search"
                placeholder="Type to Search"
                style="float: right;"
              />
            </b-col>
          </b-row>
  
          <b-row>
            <b-col cols="12">
              <b-table
                :filter="search"
                :tbody-tr-class="rowClass"
                hover
                responsive
                small
                head-variant="dark"
                outlined
                :items="filteredItems"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                show-empty
              >
                <template v-slot:cell(actions)="row">
                    <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                      <template #button-content>
                        <feather-icon size="1x" icon="MenuIcon"></feather-icon>
                      </template>
                      <b-dropdown-item>
                        <b-button
                          variant="success"
                          class="mr-1"
                          :to="{
                            name: 'view-fetch',
                            params: { id: row.item.id },
                          }"
                          size="sm"
                        >
                          <feather-icon size="1x" icon="EyeIcon"></feather-icon>
                        </b-button>
                      </b-dropdown-item>
                    </b-dropdown>
                </template>
                <template v-slot:cell(binLocation)="data">
                  <div class="custom-bold-and-large">{{ data.value }}</div>
                </template>
                <template v-slot:cell(drumID)="data">
                  <div class="custom-bold-and-large">{{ data.value }}</div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" class="pt-1">
              <b-form-group
                label="Data Per Page"
                label-for="per-page-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  @input="queryDrumPicking()"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="pt-1">
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                @change="onPageChange"
                :total-rows="totalRows"
                first-number
                last-number
                class="float-right"
                aria-controls="user-table"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <!-- modal for idle time out -->
      <b-modal
        v-model="afkmodal"
        id="modal-prevent-closing"
        ref="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
        <template #modal-header="{}">
          <!-- Emulate built in modal header close button action -->
          <h5>Pause Job</h5>
        </template>
  
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Select a Reason:"
            label-for="reason-select"
            invalid-feedback="Please select a reason"
            :state="reasonState"
          >
            <b-form-select
              id="reason-select"
              v-model="pauseReasonDefault"
              :options="pauseReasonOptions"
              :state="reasonState"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Please select a your Pause Reason --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </form>
        <template #modal-footer="">
          <b-button :disabled="loading" @click="restartAfkModal">save</b-button>
        </template>
      </b-modal>
    </div>
  </b-overlay>
  </template>
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { dateFormat, userAccess, viewAccess } from "@/utils/utils.js";
  
  export default {
    data() {
      return {
        reasonRules: [(value) => !!value || "Please select a reason"],
        tempEditId: "",
        tempEditData: "",
        dissableStart: false,
        dissableFinish: true,
        stackedStatus: "md",
        dataProps: {},
        editDetailJOState: false,
        detailJOState: false, // Job Order detail when job order has done to select
        search: "",
        reason: "",
        filterStatus: "All",
        statusOptions: ["All", "Job To Do", "Completed"],
        // detailStatus: false,
        addModal: false, // modal for add fetch
        detailModal: false,
        processModal: false,
        drumProcessModal: false,
        selectJobModal: false,
        babyModal: false,
        waitingModal: false,
        rejectModal: false,
        selectMachineModal: false,
        editModal: false,
        modalDelete: false,
        showSpinner: false,
        hasSelectMamaDrum: true,
        dataFetchRow: {},
        station: {},
        dataBabyRow: "",
  
        selectedJobType: "",

        searchSoNumber: "",
        soNumberList: [],

        mamaDrumSelected: "",
  
        totalRow: 1,
        pageOptions: [5, 10, 20, 100],
        sortBy: "",
        sortDesc: false,
        buttonState: false,
        currentPage: 1,
        perPage: 5,
        totalRows: 1,

  
        tempId: "",
        tempOrderNumber: "",
  
        detailJO: {
          status: "",
          props: { type: "" },
        },
        detailEditJO: {
          status: "",
          props: { type: "" },
        },
        fetchDeleteSelected: "",
        fetchDetailSelected: "",
        fetchDetailItem: [],
        // data for detail of job order selected
        tableDetailJO: "",
        tableDetailEditJO: "",
        fetchList: "",
        form: {
          status: "Machine Selected",
          machine: {},
        },
        formEdit: "",
        fieldsDetailFetch: [
          { key: "orderNumber", label: "Item" },
          { key: "actions", label: "Actions" },
        ],
  
        fieldsDetailJO: [
          { key: "orderNumber", label: "SO Number" },
          { key: "item.name", label: "Name" },
          { key: "quantity", label: "Qty" },
        ],
  
        fields: [
          // field of table
          // { key: "props.jobOrderNumber", label: "JO Number" },
          { key: "props.number", label: "Job No" },
          { key: "uniquePicklistsCount", label: "No of Picklist" },
          { key: "lineItemDescription", label: "Line Item Description"},
          { key: "quantity", label: "Quantity"},
          { key: "binLocation", label: "Bin Location",sortable:true},
          { key: "drumID", label: "Drum" },
          { key: "type", label: "Type" },
          { key: "status", label: "Status" },
          { key: "babyormama", label: "Mama/Baby" },
          { key: "createdAtFormatted", label: "Created Date" },
          { key: "cableWeight", label: "Cable Weight"},
          { key: "actions", label: "Actions" },
        ],
  
        jobList: [ "Cutting", "Direct Sale"],
  
        //Pause
        afkmodal: false,
        pauseReasonDefault: null,
        pauseReasonOptions: [],
        reasonState: null,
        pauseIntervalID: null,
        logoutIntervalID: null,
        IDLE_TIMEOUT: 0,
        idleSecondsCounter: 0,

        //for loading
        loading : false,
      };
    },
    mounted() {
      this.$store.dispatch("picklist/getPicklist");
      this.$store.dispatch("plant/getStations");
  
      
      // Saving Menu Setting on localstorage session so it still same even after reloading the page
      if (this.$session.has("perPageCompletedDrumPicking")) {
        this.perPage = this.$session.get("perPageCompletedDrumPicking");
      }
      if (this.$session.has("sortByCompletedDrumPicking")) {
        this.sortBy = this.$session.get("sortByCompletedDrumPicking");
      }
      if (this.$session.has("sortDescCompletedDrumPicking")) {
        this.sortDesc = this.$session.get("sortDescCompletedDrumPicking");
      }
      
      this.queryDrumPicking();
      //idle time out modal mounted
      let user = this.$store.getters["auth/getActiveUser"]
      this.$store.dispatch("pause/getActivePauseJob", user).then((res)=> {
        /* 
        result "res.pause" will be true or false
        if database has document pausejob with current username and idleRun = true,
        modal will appeared
        */
        this.afkmodal = res.pause
        res.pause ? this.startLogoutInterval() : this.startPauseInterval()
      })
      this.$store.dispatch("pause/getPauseJobSetting").then((res) =>{
        this.IDLE_TIMEOUT = res[0].intervalTime * 60
      })
      document.onclick = () => {
        this.idleSecondsCounter = 0;
      };
      document.onmousemove = () => {
        this.idleSecondsCounter = 0;
      };
      document.onkeydown = () => {
        this.idleSecondsCounter = 0;
      };
      document.onscroll = () => {
        this.idleSecondsCounter = 0;
      };
    },
    destroyed() {
      this.stopPauseInterval();
    this.stopLogoutInterval();
    },
    methods: {
      ...mapActions({
        getDrumPickingOrders: "fetch/getCompletedFetch",
      }),

      processMetadata(metadata) {
        let { totalData } = metadata;
        this.totalRows = totalData;
      },

      queryDrumPicking(){
        this.getDrumPickingOrders({
          entry : this.perPage,
          page : this.currentPage,
        }).then((data) => {
          console.log("data", data)
          this.processMetadata(data.metadata)
        }).catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
          console.log(e);
        });
      },

      onPageChange(page) {
        this.currentPage = page;
        this.queryDrumPicking();
      },
      //Pause Functions - START
    restartAfkModal() {
      this.loading = true
      if (!this.checkFormValidity()) {
        return;
      }
      document.onclick = () => {
        this.idleSecondsCounter = 0;
      };
      document.onmousemove = () => {
        this.idleSecondsCounter = 0;
      };
      document.onkeydown = () => {
        this.idleSecondsCounter = 0;
      };
      document.onscroll = () => {
        this.idleSecondsCounter = 0;
      };
      this.startPauseInterval()

      if (this.pauseReasonDefault != null) {
        var pauseData = {
          user: this.userInfo,
          pauseReason: this.pauseReasonDefault,
          pauseReference: "Completed Drum Picking"
        };
        this.$store
          .dispatch("pause/savePauseJob", pauseData )
          .then((res) => {
            this.loading = false
            console.log("puase saved", res, pauseData);
            this.afkmodal = false;
            this.stopLogoutInterval();
            this.$bvToast.toast("PauseJob Updated", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          })
          .catch((err) => {
            this.loading = false
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "warning",
              solid: true,
            });
          });
      }
    },
    checkIdleTime() {
      this.idleSecondsCounter++;
      // console.log(this.IDLE_TIMEOUT,":",this.idleSecondsCounter);
      if (this.idleSecondsCounter == this.IDLE_TIMEOUT) {
        var pauseData = {
          user: this.userInfo,
          pauseTimestamp: new Date(),
          props: {
            idleRun: true,
          },
          pauseInterval: this.pauseSetting.intervalTime,
        };
          this.$store
            .dispatch("pause/addPauseJob", pauseData)
            .then((res) => {
              this.stopPauseInterval();
              this.startLogoutInterval()
              this.afkmodal = true;
              this.$bvToast.toast("PauseJob Added", {
                title: "Success",
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.$bvToast.toast(err, {
                title: "Error",
                variant: "warning",
                solid: true,
              });
            });
      }
    },

    ajaxSearch(search, loading) {
        // return console.log(this.filteredItems)
      loading(true);
        if (search !== "") {
          var orderNumberPicklist = this.filteredItems.map(x => x.soNumber);

          orderNumberPicklist = orderNumberPicklist.filter(soNumber => soNumber !== undefined && soNumber !== "");

          let uniqueData = orderNumberPicklist.filter((value, index, self) => self.indexOf(value) === index);
          this.soNumberList = uniqueData.filter(soNumber => soNumber.includes(search));

          this.soNumberList.sort((a, b) => a.localeCompare(b));

        }
      loading(false);
    },
    /* 
      when idle timeout modal showed, this function will started
      and running an interval to check if current time as same as
      auto logout time from pause setting, if it happend system will auto logout 
    */
    idleToLogout() {
      var TimeSet = this.pauseSetting.logoutSessionMorning.split(":");
      var TimeSet2 = this.pauseSetting.logoutSessionNight.split(":");
      console.log(new Date().getHours(), ':',TimeSet[0], ' ', new Date().getMinutes(),':' ,TimeSet[1]);
      if (
        (new Date().getHours() == TimeSet[0] &&
          new Date().getMinutes() == TimeSet[1]) ||
        (new Date().getHours() == TimeSet2[0] &&
          new Date().getMinutes() == TimeSet2[1])
      ) {
        this.stopLogoutInterval();
        var pauseData = {
          user: this.userInfo,
          pauseReason: "Idle auto Logout",
          joNumber: this.form.jobOrder.orderNumber,
          picklisNO: this.form.pickListNumber,
          pauseReference: "HMI Cutter"
        };
        this.$store
        .dispatch("pause/savePauseJob", pauseData )
        .then((res) => {
          this.$bvToast.toast("PauseJob Updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          //logout function
          axios.post("/common/auth/logout").then(() => {        
            this.$session.clear()
            this.$session.destroy()
            window.location.reload()
          })
          .catch((err) => {
            this.$bvToast.toast(err.response.data.errors[0].message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          });
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
      }
    },
    startPauseInterval() {
      this.pauseIntervalID = window.setInterval(this.checkIdleTime, 1000);
      console.log("startPauseInterval init");
    },
    stopPauseInterval() {
      window.clearInterval(this.pauseIntervalID);
      console.log("stopPauseInterval init");
    },
    startLogoutInterval() {
      this.logoutIntervalID = window.setInterval(this.idleToLogout, 1000);
      console.log("startLogoutInterval init");
    },
    stopLogoutInterval() {
      window.clearInterval(this.logoutIntervalID);
      console.log("stopLogoutInterval init");
    },
    //Pause Functions - END
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.reasonState = valid;
      return valid;
    },
    debounceSearch(event) {
      this.showSpinner = true;
      clearTimeout(this.debounce);
      
      this.debounce = setTimeout(() => {
        this.showSpinner = false;
        this.search = event;
      }, 600);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      // if (item.props.rejectedMachine == true) return "row-reject";
      // if (item.props.waitingForMachine == true) return "row-waiting";
    },
    },
    computed: {
      ...mapGetters({
        fetch: "fetch/getFetch",
      }),
      userInfo() {
      const user = this.$store.getters["auth/getActiveUser"]
      return { username: user.username, email: user.email}
    },
      permission() {
        return userAccess("completed job order");
      },
      pauseSetting() {
        let pauseSetting = this.$store.getters["pause/getPauseSetting"];
        return pauseSetting[0];
      },
      getPauseJob() {
        let pauseSetting = this.$store.getters["pause/getPauseSetting"];
        this.IDLE_TIMEOUT = pauseSetting[0]?.intervalTime * 60;
        var user = this.$store.getters["auth/getActiveUser"];
        let pausejobs = this.$store.getters["pause/getPauseJobs"];
        var currpause = pausejobs.filter(
          (x) => x.user?.username == user.username || x.user?.email == user.email
        );
        var hasIdleTrue = currpause.filter((data) => data.props.idleRun == true);
        if (hasIdleTrue.length == 1) {
          this.idleSecondsCounter = this.IDLE_TIMEOUT - 1;
          return hasIdleTrue[0];
        } else {
          return currpause[0];
        }
      },
      getUser() {
        var user = this.$store.getters["auth/getActiveUser"];
        return { username: user.username, email: user.email };
      },
  
      selectMachine() {
        return this.$store.getters["plant/getStations"].map((el) => {
          return {
            ...el,
            label: el.name,
          };
        });
      },
  
      listItem() {
        var dataFetch = this.fetch? this.fetch.filter(el => (el.status === "Picking Ended" && el.props.type == "Direct Sale") || el.status === "Machine Selected" || el.status === "Completed" || el.status === "Rejected" || el.status === "Job Cancelled" ) : []
        return dataFetch.map((el) => {
          let status = "";
          if (el.status === "Rejected") {
            status = "Can't Pick";
          } else if (el.status === "Approved") {
            status = "Picking";
          } else {
            status = el.status;
          }
          var isBabyDrum;
          if (el.props.needPicker == true) {
            isBabyDrum = "Baby Drum";
          } else {
            isBabyDrum = "Mama Drum";
          }
          let pickList = el.item.map((item) =>
            item.length != 0 ? item.orderNumber : el.props.pickListNumber
          );
          return {
            ...el,
            fetchingID:
              el.orderNumber.split("/")[2] +
              "/" +
              el.orderNumber.split("/")[3] +
              "/" +
              el.orderNumber.split("/")[4],
            pickList: pickList.join(", "),
            createdAtFormatted: dateFormat(el.createdAt).split(',')[0],
            status: status,
            babyormama: isBabyDrum,
          };
        });
      },
      filteredItems() {
        let drumPicking = this.$store.getters["fetch/getCompletedFetch"].map(el => {
          let status = "";
          if (el.status === "Rejected") {
            status = "Can't Pick";
          } else if (el.status === "Approved") {
            status = "Picking";
          } else {
            status = el.status;
          }
          var isBabyDrum;
          if (el.props.needPicker == true) {
            isBabyDrum = "Baby Drum";
          } else {
            isBabyDrum = "Mama Drum";
          }
          let pickList = el.item.map((item) =>
            item.length != 0 ? item.orderNumber : el.props.pickListNumber
          );
          return {
            ...el,
            fetchingID:
              el.orderNumber.split("/")[2] +
              "/" +
              el.orderNumber.split("/")[3] +
              "/" +
              el.orderNumber.split("/")[4],
            pickList: pickList.join(", "),
            createdAtFormatted: dateFormat(el.createdAt).split(',')[0],
            status: status,
            babyormama: isBabyDrum,
          };
        })
        .map((item) => {
          let newType = item.props.type;
          if (item.props.type === "Direct Sale") {
            newType = "Direct Sale - 直销";
          } else if (item.props.type === "Cutting") {
            newType = "Cutting - 剪线";
          } else if (item.props.type === "Recoil") {
            newType = "Recoil - 卷线换轴";  
          }

          var picklistArr = [];
          item.item.map((el) => {
            if (el.pickList && el.pickList.id) {
              picklistArr.push(el.pickList.id);
            } else {
              picklistArr.push([]);
            }
          })

          const uniquePicklistsCount = new Set(picklistArr).size;

          let newPickList = item.pickList.id;

          if (item.babyormama == "Mama Drum") {
            newPickList = ""
          }
          if (item.babyormama == "Baby Drum") {
            newPickList = item.pickList.id
          }
  
          if (item.drumID == null) {
            item.drumID = item.props.babyDrumType
  
            if (item.props.babyDrumType == "W"){
            item.drumID = "Wooden"
          }
            else if (item.props.babyDrumType == "S"){
            item.drumID = "Steel"
            }
          if (item.props.babyDrumType == "A"){
            item.drumID = "Active"
          }
            else if (item.props.babyDrumType == "I"){
              item.drumID = "Inactive"
            }
          }
  
          return {
            ...item,
            pickList: newPickList,
            type: newType,
            fromBatch: item.item[0].pickList ? item.item[0].pickList.fromBatch : '',
            binLocation: item.props.binLocation,
            lineItemDescription: item.item[0].pickList ? item.item[0].pickList.lineItem + "," + item.item[0].pickList.lineDescription : '',
            cableLength: item.item[0].pickList ? item.item[0].pickList.cableLength : '',
            quantity: item.item[0].pickList ? item.item[0].pickList.quantity : '',
            cableWeight: item.item[0].pickList ? item.item[0].pickList.cableWeight : '',
            soNumber: item.item[0].pickList ? item.item[0].pickList.orderNumber : '',
            uniquePicklistsCount: uniquePicklistsCount
          };
        });

        if(this.searchSoNumber != "" && this.searchSoNumber != null){
          let finalData = drumPicking.filter(
            (picklist) =>
            picklist.soNumber == this.searchSoNumber
          );
          
          return finalData;
        }
        return drumPicking
      },
      rows() {
        return this.filteredItems.length;
      },
    },
    watch: {
      afkmodal(value) {
      if(value) {
        // get reason list of pause job
        this.$store.dispatch("plant/getReasonList").then((res) => {
          var PauseJobReason = res.filter((x) => x.reasonName == "Pause Job");
          var allreason = PauseJobReason[0].reasonList;
          var activeReason = allreason.filter((x) => x.status === "Active")
          this.pauseReasonOptions = activeReason.map((x) => x.reason)
        });
      }
    },
      perPage(perPageNew) {
        this.$session.set("perPageCompletedDrumPicking", perPageNew);
      },
      sortBy(sortByNew) {
        this.$session.set("sortByCompletedDrumPicking", sortByNew);
      },
      sortDesc(sortDescNew) {
        this.$session.set("sortDescCompletedDrumPicking", sortDescNew);
      },
    },
  };
  </script>
  <style>
  .row-reject {
    background-color: yellow;
  }
  .row-waiting {
    background-color: rgb(255, 190, 70);
  }
  .custom-bold-and-large {
    font-weight: bold;
    font-size: 18px;
  }
  </style>
  